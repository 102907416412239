import { env } from '../constants'
import { Howl } from 'howler'

/* Fields */
const sounds = [
    { key: "sound", src: `${env.mediapoint}/sounds/chip1.mp3` },
    { key: "win", src: `${env.mediapoint}/sounds/winbig.mp3` },
    { key: "winsmall", src: `${env.mediapoint}/sounds/winsmall.mp3` },
    { key: "repeat", src: `${env.mediapoint}/sounds/repeat.mp3` },
    { key: "selectchip", src: `${env.mediapoint}/sounds/selectchip.mp3` },
    { key: "clickuibut", src: `${env.mediapoint}/sounds/card.mp3` },
]

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

let soundEffects = []

sounds.forEach(sound => {

    if (sound.key !== "sound" && sound.key !== "selectchip") {
        const soundEffect = new Howl({
            src: [sound.src],
            volume: 0,
            mute: true,
            loop: false,
            html5: true,
            format: ["mp3"],
            onplayerror: () => {
                soundEffect.once('unlock', () => {
                    soundEffect.play()
                })
            }
        })

        soundEffects.push({ key: sound.key, src: sound.src, audio: soundEffect })
    }

})


/* Audio */
const play = (sound = null, volume = 1) => {

    if (!sound) {
        return
    }


    if (isIOS && sound !== "sound" && sound !== "selectchip") {
        const index = soundEffects.findIndex(e => e.key === sound)
        const soundEffect = soundEffects[index].audio
        soundEffect.stop()
        soundEffect.volume(volume)
        soundEffect.mute(false)
        soundEffect.play()
    }
    else {

        const index = sounds.findIndex(e => e.key === sound)

        if (index > -1) {
            const soundEffect = new Howl({
                autoplay: true,
                src: [sounds[index].src],
                volume,
                loop: false,
                html5: true,
                format: ["mp3"]
            })

            soundEffect.play()
        }

    }

    return
}

export { soundEffects, play }