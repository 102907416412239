import React, { Component } from "react"

/* Constants */
import { env } from '../constants'


/* Widget Busy */
class Busy extends Component {

    render = () => {

        const { isBusy, onConnect } = this.props

        if (isBusy) {
            return (
                <div className="busy">
                    <div className="busy-title">Стол занят</div>
                </div>
            )
        }

        return (
            <div className="busy">

                <div className="busy-title">Займите место</div>

                <div onClick={() => onConnect()} className="busy-connect">

                    <div className="busy-connect-image">
                        <img src={`${env.mediapoint}/images/user-connect.png`} alt="User" />
                    </div>

                    <div className="busy-play">Играть</div>

                </div>
            </div>
        )
    }

}

export default Busy