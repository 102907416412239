import React, { Component } from "react"

/* Constants */
import { env } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"

const tempCards = [0, 1, 2, 3]

/* Widget Player Cards */
class PlayerCards extends Component {


    constructor(props) {
        super(props)

        const activeCards = props.exchangeCards

        this.state = {
            activeCards
        }
    }

    get_unique_values = (arr, prop) => {
        return arr.filter((obj, index, self) =>
            index === self.findIndex((o) =>
                o[prop] === obj[prop]
            )
        )
    }

    /* Exchange action */
    exchange = card => {

        const { used, setExchangeCards, box } = this.props
        let activeCards = this.state.activeCards

        if (!used) {

            let boxExchangeCards = activeCards[box] ? activeCards[box] : []

            const index = boxExchangeCards.findIndex(c => c.id === card.id)

            let card_is_added = false

            if (index > -1) {
                boxExchangeCards.splice(index, 1)
            }
            else {
                boxExchangeCards.push(card)
                card_is_added = true
            }

            let exchangeCardsDemo = activeCards

            exchangeCardsDemo[box] = boxExchangeCards

            this.setState({ activeCard: exchangeCardsDemo })
            setExchangeCards({ cards: exchangeCardsDemo, card_is_added })

        }

    }


    /* Card status */
    status = card => {

        const { game, sixthGame } = this.props

        let status = false

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = true
                }
            }
        }

        if (sixthGame && Array.isArray(sixthGame) && sixthGame.length > 0) {
            sixthGame.forEach(item => {
                const index = item.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
                if (index > -1) {
                    if (item.data[index].status) {
                        status = true
                    }
                }
            })
        }

        return status
    }


    /* Draw card image */
    _image = card => {

        const status = this.status(card)
        const style = status ? 'animated-card-image' : ''

        return <Picture className={style} src={`${env.mediapoint}/cards/${card.image}`} alt="Player Card" />
    }


    /* Draw flip animation box */
    _flip = card => {

        const { activeCards } = this.state
        const { box } = this.props

        const boxExchangeCards = activeCards[box] ? activeCards[box] : []

        const i = (boxExchangeCards && Array.isArray(boxExchangeCards) && boxExchangeCards.length > 0) ? boxExchangeCards.findIndex(a => parseInt(a.id) === parseInt(card.id)) : -1

        const isActive = i > -1 ? '' : 'active'

        return (
            <div className={`player-flip-card ${isActive}`}>
                <div className="player-flip-card-inner">
                    <div className="player-flip-card-front">
                        <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Player Card" />
                    </div>
                    <div className="player-flip-card-back">
                        {this._image(card)}
                    </div>
                </div>
            </div>
        )
    }


    /* Draw Status */
    _status = card => {

        const { activeCards } = this.state
        const { box } = this.props

        const boxExchangeCards = activeCards[box] ? activeCards[box] : []

        const index = boxExchangeCards.findIndex(e => parseInt(e.id) === parseInt(card.id))

        const status = this.status(card)

        const style = status ? 'player-card-status-animation' : ''

        return (
            <div className={`player-card-status ${style}`} style={index === -1 ? { opacity: 1 } : { opacity: 0 }}>

                {(card.isSixth && card.isSixth === true) && <div className="mini-status">+1</div>}

                {(card.isExchange && card.isExchange === true) &&
                    <div className="mini-status">
                        <Picture src={`${env.mediapoint}/images/exchange.png`} alt="Exchange" />
                    </div>
                }

                {status && <div className="player-card-status-corner" />}
            </div>
        )

    }


    /* Draw card */
    _card = () => {

        const { cards, used } = this.props

        const { activeCards } = this.state
        const { box } = this.props

        const boxExchangeCards = activeCards[box] ? activeCards[box] : []

        const filtered_cards = this.get_unique_values(cards, "id")

        return (
            <div className="player-cards">
                {filtered_cards.map((card) => {

                    const index = boxExchangeCards.findIndex(a => a.id === card.id)

                    if (index > -1 && used) {
                        return <div />
                    }
                    return (
                        <div onClick={() => this.exchange(card)} className="player-card" key={`pCard-${card.uid}`}>
                            {this._flip(card)}
                            {this._status(card)}
                        </div>
                    )
                })}
                <div className="player-cards-places">
                    {
                        tempCards.map(item =>
                            <div key={`place-${box}-${item}`} className="player-card-place" />
                        )
                    }
                </div>
            </div>
        )
    }


    /* Draw player game result */
    _result = (style = '') => {

        const { game, sixthGame } = this.props

        let result = ""

        if (sixthGame) {

            result = ""

            if (sixthGame && Array.isArray(sixthGame) && sixthGame.length > 0) {
                sixthGame.forEach((sg, index) => {
                    if (index <= 1) {
                        if (parseInt(sg.level) > 0) {
                            if (index === 1) {
                                result = result + " + "
                            }

                            result = result + sg.name
                        }
                    }
                })
            }

            if (result === "") {
                result = "Нет игры"
            }
        }
        else {
            if (game !== null) {
                if (parseInt(game.level) === 0) {
                    result = "Нет игры"
                }
                if (parseInt(game.level) > 0) {
                    result = game.name
                    if (game.hasAceKing) {
                        result = result + " + Туз Король"
                    }
                }
            }
        }

        if (result !== "") {
            if (result === "Нет игры") {
                return <div className={`player-result player-result-red ${style}`}>{result}</div>
            }
            else {
                return <div className={`player-result ${style}`}>{result}</div>
            }
        }
    }


    render = () => {

        const { box, position } = this.props

        return (
            <div className={`player-cards-box ${position}`}>
                <h2 style={{ opacity: 1 }}><p className="mainBoxNumber">Бокс {box + 1}</p>{this._result('mobile')}</h2>
                {this._card()}
                {this._result()}
            </div>
        )
    }

}

export default PlayerCards