import React, { Component } from "react"

/* Constants */
import { utils, sound, env } from '../constants'

/* UUID */
import { v4 as uuidv4 } from 'uuid'

/* Components */
import { Picture, VipRates } from "@alicorn/poker-ui"
import { CHOICE } from "../constants/status"


/* Widget Bet */
class Bet extends Component {


    constructor() {
        super()

        this.state = {
            history: [],
        }
    }

    getChipsList = () => {
        const { chips } = this.props
        return utils.getChips(chips)
    }


    add = number => {

        const { history } = this.state
        const { setAnte, ante, balance, toggleBalanceError, activeChip, max, toggleMaxBetError } = this.props

        const chipsList = this.getChipsList()

        let list = history

        const index = chipsList.findIndex(c => c.key === activeChip)

        if (index > -1) {

            let activeBoxes = 0

            let tempAnte = 0

            ante.forEach(item => {
                if (item) {
                    activeBoxes += 1
                    tempAnte = item
                }
            })

            const current = chipsList[index]
            const current_ante = ante[number] ? ante[number] : 0

            if (!current_ante) {
                activeBoxes += 1
            }

            const ante_to_check = current_ante ? parseInt(current_ante + current.value) : tempAnte ? tempAnte : parseInt(current.value)

            if (balance === null || balance === undefined || parseInt(balance) === 0 || (parseInt(balance) < parseInt(ante_to_check) * activeBoxes * 4)) {
                toggleBalanceError()
                return
            }

            if (current_ante + current.value > max) {
                toggleMaxBetError()
                return
            }

            const uid = uuidv4()
            list.push({ ...current, uid, index: number, box: number })
            this.setState({ history: list })
            setAnte({ value: current_ante + current.value, index: number })

            sound.play('sound')
        }
    }


    /* Clear action */
    clear = () => {

        const { ante, setAnte } = this.props

        if (ante.length > 0) {
            this.setState({ history: [] })
            setAnte('all')
        }
    }


    /* Cancel action */
    cancel = () => {

        const { history } = this.state
        const { setAnte, ante } = this.props

        let list = history

        if (history.length > 0) {

            let last_operation = history[history.length - 1]
            const ante_value = ante[last_operation.box]

            const last = list.pop()

            if (ante_value > 0 && last) {

                let history_demo = history
                history_demo = list

                this.setState({ history: history_demo })

                const result = parseInt(ante_value) - parseInt(last.value)

                setAnte({ value: result > 0 ? result : 0, index: last_operation.box, cancel: true })

                sound.play('sound')
            }
        }
    }


    /* Double action */
    double = () => {

        const { history } = this.state
        const { setAnte, ante, balance, toggleBalanceError } = this.props

        let totalBet = 0
        ante.forEach(item => {
            if (item) {
                totalBet += parseInt(item)
            }
        })

        if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < parseInt(totalBet * 2) * 4) {
            toggleBalanceError()
            return
        }

        let list = history
        const ante_value = (ante[0] && ante[0] > 0) ? ante[0] : (ante[1] && ante[1] > 0) ? ante[1] : 0

        let index = (ante[0] && ante[0] > 0) ? 0 : (ante[1] && ante[1] > 0) ? 1 : null

        if ((ante_value > 0) && index !== null) {

            const chipsList = this.getChipsList()

            let image = {}
            chipsList.forEach(chip => {
                if (parseInt(ante_value) >= chip.value) {
                    image = chip.image
                }
            })

            const uid = uuidv4()
            list.push({ key: 7, value: ante_value, index: index, image, image3d: null, uid })
            this.setState({ history: list })
            setAnte({ value: ante_value * 2, index: index })

            sound.play('sound')
        }
    }


    /* Refresh action */
    refresh = () => {

        const { history } = this.state
        const { setAnte, ante, last, boxes, balance, toggleBalanceError } = this.props

        let totalBet = 0
        let total_last = 0

        ante.forEach(item => {
            totalBet += item
        })

        last.forEach(item => {
            total_last += item
        })

        if (total_last > 0 && totalBet === 0) {

            if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) < (parseInt(total_last) * 4)) {
                toggleBalanceError()
                return
            }

            if (Array.isArray(boxes) && boxes.length > 0) {
                boxes.forEach((_, index) => {

                    const box_ind = index === 0 ? 1 : 0

                    const last_value = last[box_ind]
                    if (last_value) {
                        let image = {}

                        const chipsList = this.getChipsList()

                        chipsList.forEach(chip => {
                            if (parseInt(last_value) >= chip.value) {
                                image = chip.image
                            }
                        })

                        const uid = uuidv4()
                        let list = history
                        list.push({ key: 8, value: last_value, image, image3d: null, uid, box: box_ind })
                        this.setState({ history: list })
                        setAnte({ value: last_value, index: box_ind })

                        sound.play('sound')
                    }
                })
            }
        }

    }



    /* Play action */
    play = () => {

        const { start, ante } = this.props
        let totalBet = 0

        ante.forEach(item => {
            totalBet += item
        })

        if (totalBet > 0) {
            start()
        }
    }

    array_length = (array) => {

        let cnt = 0

        array.forEach(item => {
            if (item) {
                cnt += 1
            }
        })

        return cnt
    }

    setActiveChip = (item) => {
        const { activeChip, setActiveChip } = this.props

        if (item.key !== activeChip) {
            setActiveChip(item.key)
        } else {
            const index = 0
            this.add(index)
        }
    }

    /* Draw Chips */
    _chips = () => {

        const { activeChip } = this.props

        const list = this.getChipsList()

        return (
            <div className="chips">

                {list.map((item, index) =>
                    <div onClick={() => this.setActiveChip(item)} key={`${index}`} className={item.key === activeChip ? `chip active-chip` : `chip`}>
                        <Picture src={`${item.image}`} alt={`CHIP ${item.value}`} />
                        <div className={`chip-value size_${utils.fontSize(item.value)}`}>{utils.counter(item.value)}</div>
                    </div>
                )}

            </div>

        )
    }


    render = () => {

        const { history } = this.state
        const { ante, last, boxes, currency, activeChip, chips } = this.props

        const disabled = this.array_length(ante) === 0

        return (
            <div className="bet">

                <div className="bet-panel">

                    <div className="bet-buttons">

                        <div className="bet-empty-space" />

                        {/* Clear Button */}
                        <div className={`bet-button ${disabled && 'disabled'}`} onClick={() => this.clear()}>
                            <Picture src={`${env.mediapoint}/images/bet/delete.png`} alt="Delete" />
                            <span>Очистить</span>
                        </div>

                        {/* Cancel Button */}
                        <div className={`bet-button ${disabled && 'disabled'}`} onClick={() => this.cancel()}>
                            <Picture src={`${env.mediapoint}/images/bet/cancel.png`} alt="Cancel" />
                            <span>Отменить</span>
                        </div>

                    </div>

                    {this._chips()}

                    <div className="bet-buttons">

                        {/* Double Up Button */}
                        <div className={`bet-button ${disabled && 'disabled'}`} onClick={() => this.double()}>
                            <div className="bet-title">2x</div>
                            <span>Удвоить</span>
                        </div>

                        {/* Cancel Button */}
                        <div className={`bet-button ${(this.array_length(last) === 0 || this.array_length(ante) > 0) && 'disabled'}`} onClick={() => this.refresh()}>
                            <Picture src={`${env.mediapoint}/images/bet/refresh.png`} alt="Refresh" />
                            <span>Повторить</span>
                        </div>

                        {/* Play Button */}
                        <div className={`bet-button bet-play-button ${disabled && 'disabled'}`} onClick={() => this.play()}>
                            <Picture src={`${env.mediapoint}/images/bet/play.png`} alt="Play" />
                            <span>Играть</span>
                        </div>

                    </div>

                </div>

                <div className="pc-rates-outer">
                    <VipRates
                        status={CHOICE}
                        currency={currency}
                        history={history}
                        fromBet={true}
                        add={(value) => this.add(value)}
                        result boxes={boxes}
                        ante={ante}
                        active={activeChip}
                        bet={() => { }}
                        chips={chips}
                    />
                </div>


            </div>
        )
    }

}

export default Bet