import React, { Component } from "react"

/* Modal */
import Modal from 'react-modal'

/* Set modal target */
Modal.setAppElement('#root')


/*
    Component CustomModal
*/
class CustomModal extends Component {


    render = () => {

        const { open, close, children } = this.props

        return (
            <Modal
                isOpen={open}
                onRequestClose={() => close()}
                closeTimeoutMS={300}
                className="mobile-menu-modal"
                overlayClassName="mobile-menu-overlay"
            >
                {children}
            </Modal >
        )
    }
}

export default CustomModal