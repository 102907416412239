import React, { Component } from "react"

/* Socket IO */
import { io } from "socket.io-client"

/* Widgets */
import { Preloader, Bet, DealerCards, PlayerCards, Busy } from './widgets'

/* Components */
import { GamePayout, Timing } from './components'

/* Constants */
import { images, env, sound, utils } from './constants'
import { LOADING, ERROR, CHOICE, GAME, WAITING, BUSY, INTERVAL } from './constants/status'

/* Alicorn Poker UI */
import {
    Background, Balance, GameControl, GameInfo, Status, Error, Notification, Internet,
    Pass, Play, Fifth, Swap, Insurance, FloatingMessages,
    VipRates, VipMobileRate
} from '@alicorn/poker-ui'

/* Rules */
import { list } from './constants/rules'

/* JWT */
import { decodeToken } from 'react-jwt'

/* REST API */
import { history } from './api/History'

/* SWAL */
import Swal from "sweetalert2"

/* UUID */
import { v4 as uuidv4 } from 'uuid'

/* Game quit alicorn */
import { setIframeMessageSenderSettings, useSendInitIframeMessage } from '@alicorn/iframe-message-sender'

/* Intenet speed check url */
const imgUrl = "https://testing-cb.makaobet.com/images/dealerinfo/close.png"

/* BOX LENGTH */
const BOXES = [1, 2]

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

setIframeMessageSenderSettings({ parentName: 'makao-front', sourceName: 'poker', isChild: true })

/* Entry Point */
class App extends Component {


    constructor() {
        super()

        const data = decodeToken(token)

        this.state = {

            /* General */
            cached: false,
            status: LOADING,
            isTableEmpty: false,

            balance: null,
            total: 0,
            currency: (token && data && data.currency) ? data.currency : 'USD',
            identifier: (token && data && data.identifier) ? data.identifier : '1',
            lastWin: 0,

            /* Game */
            activeChip: 3,
            activeBoxes: 0,
            activeButtons: [0, 0],
            ante: [0, 0],
            last: [0, 0],
            dealt: false,
            actions: [0, 0],
            game: [0, 0],
            isBet: [0, 0],

            freezedBoxes: [],

            isOpen: [0, 0],
            result: [0, 0],

            gameInfo: null,
            transactions: [],

            operations: [],

            /* Dealer Card */
            dealer: [],
            dealerGame: null,

            /* Player Card */
            player: [[], []],
            playerGame: [],

            /* Dealer */
            dealerInfo: null,

            max: data && data.max ? data.max : 5000,
            min: data && data.min ? data.min : 50,
            maxPay: data && data.maxPay ? data.maxPay : 100000,

            /* Exchange */
            used: [0, 0],
            exchangeCards: [],

            /* Purchase */
            isPurchase: [0, 0],
            boxesToPurchase: 0,
            purchaseResult: [0, 0],
            removedCard: null,

            betIsTouched: [0, 0],

            /* Insurance */
            showInsurance: [0, 0],
            insurance: [0, 0],
            insuranceStatus: [0, 0],

            shadow: true,

            histories: [],
            historyLoading: true,
            historyPage: 1,
            historyHasMore: true,

            closed: false,
            newDevice: false,
            isDisconnected: false,

            isPaused: false,

            /* Other */
            hls: data && data.hls ? data.hls : "",
            chips: data && data.chips && Array.isArray(data.chips) ? data.chips : [],
            backColor: data && data.backColor ? data.backColor : "",
            gameImg: data && data.gameImg ? `${env.mediapoint}/images/table-images/` + data.gameImg : null,
        }

        this.notify = React.createRef()

        /* Start connection */
        this.socket = io(env.endpoint, { auth: { token: `${token}`, reconnection: false } })

        /* Timing ref */
        this._timing = null
        this._balanceErrorTiming = null
        this._maxBetErrorTiming = null
    }


    componentDidMount = () => {
        this.game()
        this.cache()
        this.loadHistory(1)
    }

    componentWillUnmount = () => {
        this.socket.disconnect()
        this.clear()
    }

    /* quitGame */
    quitGame = () => {
        // this.socket.emit("quitGame", "yes")
    }

    /* Load History */
    loadHistory = (page = 1) => {

        const { histories } = this.state

        if (page > 1) {
            this.setState({ historyPage: page })
        }

        history(token, page).then(response => {
            if (response.status === 200) {

                let list = response.data
                if (page > 1) {
                    list = [...histories, ...response.data]
                }

                if (response.data.length < 10) {
                    this.setState({ historyHasMore: false })
                }

                this.setState({ histories: list, historyLoading: false })
            }
        }).catch(() => {
            this.setState({ histories: [], historyLoading: false })
        })
    }

    /* Send Message */
    sendMessage = message => {
        this.socket.emit("message", message.message)
        if (this._floatingMessages) {
            this._floatingMessages.sendMessage(message)
        }
    }

    /* Game Evenets */
    game = () => {

        /* On error */
        this.socket.on("connect_error", () => {

            this.clear()

            const timer = setTimeout(() => {
                this.setState({ status: ERROR })
                clearTimeout(timer)
            }, 1000)

        })

        /*  */
        this.socket.on("disconnect", () => {
            this.setState({ isDisconnected: true })
            this.clear()
        })

        /* On connect */
        this.socket.on("connect", () => {
            const timer = setTimeout(() => {
                this.setState({ status: WAITING })
                clearTimeout(timer)
            }, 2000)
        })

        /* On New Device */
        this.socket.on("newDeviceConnection", () => {
            this.setState({ newDevice: true })
            this.socket.disconnect()
            this.clear()
        })

        /* On connect */
        this.socket.on("isBusy", () => {
            const timer = setTimeout(() => {
                this.setState({ status: BUSY, isTableEmpty: false })
                this.notifyToggle("busy", 'show')
                clearTimeout(timer)
            }, 2050)
        })

        /* On Table is Empty */
        this.socket.on("emptyTable", () => {
            this.setState({ isTableEmpty: true })
            this.notifyToggle("busy", 'close')
        })

        /* Get Dealer Info */
        this.socket.on("dealerInfo", data => {

            const { dealerInfo } = this.state

            /* need to write reconncetion for dealer monitor */
            const timer = setTimeout(() => {
                this.setState({ dealerInfo: data })
                if (data) {
                    if (!dealerInfo) {
                        this.setState({ status: CHOICE, isTableEmpty: false })
                    }
                }
                else {
                    this.clear(WAITING)
                }
                clearTimeout(timer)
            }, 2000)

            setTimeout(() => { this.setDealerStatus(data) }, data ? 2000 : 500)

        })


        /* Update Dealer Info */
        this.socket.on("dealerConnect", data => {

            const { dealerInfo } = this.state

            if (dealerInfo) {
                this.setState({ dealerInfo: data.storage })
            }
            else {
                const timer = setTimeout(() => {
                    this.setState({ dealerInfo: data.storage, status: data ? CHOICE : WAITING })
                    clearTimeout(timer)
                }, 2020)
            }

            this.setDealerStatus(data.storage)

        })

        /* Reconnection */
        this.socket.on("reconnection", data => {

            if (data) {

                if (data && data.reason && data.reason === "transaction") {
                    if (this.notify) {
                        this.notifyToggle("transaction", 'show')
                    }
                }

                this.reconnectionClear()
            }

            const timer = setTimeout(() => {

                let game = ['start', 'start']
                let isPaused = data.isPaused
                let boxes = data.boxes
                let playerCards = data.player
                let playerGame = data.playerGame
                let used = [0, 0]
                let swapCards = []
                let insurance = [0, 0]
                let insuranceStatus = [0, 0]
                let isPurchase = [0, 0]
                let removedCard = null
                let dealerGame = null
                let freezedBoxes = data.freezedBoxes
                let boxesToPurchase = data.boxesToPurchase
                let purchaseBox = data.purchaseBox
                let total = data.total
                let actions = [0, 0]

                if (data.isFifth) {

                    data.isFifth.forEach((item, index) => {
                        if (item) {
                            game[index] = 'start'
                            used[index] = true
                            actions[index] = "fifth"
                        }
                        if (data.fifthCard && data.fifthCard[index]) {
                            playerCards[index].unshift({ ...data.fifthCard[index], isFifth: true })
                            actions[index] = null
                        } else {
                            playerGame[index] = null
                        }
                    })
                }

                if (data.exchange && Array.isArray(data.exchange) && data.exchange.length > 0) {
                    data.exchange.forEach((item, index) => {
                        if (item) {
                            game[index] = 'exchange'
                            playerGame[index] = null
                            used[index] = true
                            actions[index] = "exchange"
                        }
                    })
                }

                if (data.exchangeCards && Array.isArray(data.exchangeCards) && data.exchangeCards.length > 0) {
                    data.exchangeCards.forEach((array, index) => {
                        if (array && Array.isArray(array) && array.length > 0) {
                            array.forEach(card => {
                                playerCards[index].unshift({ ...card, isExchange: true })
                            })
                            used[index] = true
                            actions[index] = "exchange"
                        }
                    })
                }

                if (data.swapCards && Array.isArray(data.swapCards) && data.swapCards.length > 0) {
                    swapCards = data.swapCards
                }

                if (data.insuranceValue && Array.isArray(data.insuranceValue) && data.insuranceValue.length > 0) {
                    insurance = data.insuranceValue
                }

                if (data.insuranceStatus && Array.isArray(data.insuranceStatus) && data.insuranceStatus.length > 0) {
                    insuranceStatus = data.insuranceStatus
                }

                if (purchaseBox) {
                    isPurchase = purchaseBox
                }

                if (data.removedCard) {
                    removedCard = data.removedCard
                }

                if (data.dealerGame) {
                    dealerGame = data.dealerGame
                }

                if (data.freezedBoxes && data.freezedBoxes.length > 0) {
                    setTimeout(() => {
                        data.freezedBoxes.forEach((item, index) => {
                            if (item) {
                                this.setAction(index, item, false)
                            }
                        })
                    }, 200)
                }

                if (data.pass && Array.isArray(data.pass) && data.pass.length > 0) {
                    data.pass.forEach((item, index) => {
                        if (item) {
                            game[index] = 'pass'
                            actions[index] = "pass"
                        }
                    })
                }

                if (data.bet && Array.isArray(data.bet) && data.bet.length > 0) {
                    data.bet.forEach((item, index) => {
                        if (item) {
                            game[index] = 'bet'
                            actions[index] = "bet"
                        }
                    })
                }

                this.setState({
                    status: GAME,
                    activeBoxes: boxes,
                    ante: data.ante,
                    actions,
                    player: playerCards,
                    playerGame: playerGame,
                    dealer: data.dealer,
                    dealerGame,
                    gameInfo: data.gameID,
                    transactions: data.transactions,
                    dealt: data.dealt,
                    shadow: data.shadow,
                    game: game,
                    used: used,
                    exchangeCards: swapCards,
                    total,
                    last: data.ante,
                    insurance,
                    insuranceStatus,
                    isPurchase,
                    boxesToPurchase,
                    removedCard,
                    freezedBoxes: freezedBoxes,
                    isPaused: isPaused
                })

                if (isPaused) {
                    this.notifyToggle("pause", "show")
                }

                if (data.from === "continue") {
                    setTimeout(() => {
                        this.notifyToggle('pause', 'close')
                    }, 2000)

                }

                this.notifyToggle('transaction', 'close')


                if (data.result && Array.isArray(data.result) && this.array_length(data.result) > 0) {
                    let demo_is_bet = [0, 0]
                    let demo_ante = data.ante
                    if (boxes === this.array_length(data.result)) {
                        total = 0
                    }
                    data.result.forEach((item, index) => {
                        if ((item && item.result !== 'lose') && data.bet && Array.isArray(data.bet) && data.bet[index] && data.bet[index]) {
                            demo_is_bet[index] = 'bet'
                        }
                    })
                    this.setState({ result: data.result, shadow: true, ante: demo_ante, isBet: demo_is_bet, total: total, used: [true, true] })

                }

                clearTimeout(timer)

            }, 2050)
        })

        /* Dealt */
        this.socket.on("dealt", () => {
            this.setState({ dealt: true, actions: [0, 0], game: ['start', 'start'], shadow: true })
            this.socket.emit("shadow", "yes")
        })

        /* Balance */
        this.socket.on("balance", data => {
            this.setState({ balance: data })
        })

        /* Get a Dealer card */
        this.socket.on("dealer", data => {

            sound.play('clickuibut', 0.2) // PLAY SOUND

            const { dealer } = this.state

            const index = dealer.findIndex(e => e.uid === data.uid)

            if (index === -1) {
                let cards = dealer
                cards.unshift(data)
                this.setState({ dealer: cards })
            }
        })


        this.socket.on("dealerCards", data => {
            this.setState({ dealer: data })
        })

        /* Get a Purchase Dealer card */
        this.socket.on("purchaseCard", data => {
            const { dealer } = this.state
            let cards = dealer
            cards.unshift(data)
            this.setState({ dealer: cards, actions: [0, 0], shadow: true })
            this.socket.emit("shadow", "yes")
        })

        /* Get a Dealer game */
        this.socket.on("dealerGame", data => {
            this.setState({ dealerGame: data })
        })

        /* Get a Player card */
        this.socket.on("player", data => {

            sound.play('clickuibut', 0.2) // PLAY SOUND

            if (data.card_index === 1) {
                this.setState({ dealer: [], dealerGame: null, playerGame: [] })
            }

            const { player } = this.state
            let cards = (data.card_index === 1 || data.card_index === 2) ? [] : player[data.index] ? player[data.index] : []
            const i = cards.findIndex(e => e.id === data.card.id)
            if (i === -1) {
                cards.unshift(data.card)
                let demo_player = player
                demo_player[data.index] = cards
                this.setState({ player: demo_player })
            }
        })

        /* Get a Player game */
        this.socket.on("playerGame", data => {
            let playerGameDemo = this.state.playerGame
            playerGameDemo[data.index] = data.playerGame
            this.setState({ playerGame: playerGameDemo })
        })

        /* Get a Player game */
        this.socket.on("exchangePlayerGame", data => {
            const { playerGame, game, actions } = this.state
            const { player_game, index } = data

            let demo_player_game = playerGame
            let demo_game = game
            let demo_actions = actions

            demo_player_game[index] = player_game
            demo_game[index] = 'start'
            demo_actions[index] = 0
            this.setState({ playerGame: demo_player_game, actions: demo_actions, shadow: true, game: demo_game })
            this.socket.emit("shadow", "yes")
        })

        /* Get a Exchanged player card */
        this.socket.on("exchangeCards", data => {

            sound.play('clickuibut', 0.2) // PLAY SOUND

            const { card, index } = data
            const { player, game, actions } = this.state

            let cards = player[index] ? player[index] : []

            cards.unshift({ ...card, isExchange: true })

            let demo_player = player
            demo_player[index] = cards

            let demo_game = game
            demo_game[index] = 'start'

            let demo_action = actions
            demo_action[index] = 0

            this.setState({ player: demo_player, actions: demo_action, game: demo_game })
        })

        /* Get a Fifth card */
        this.socket.on("fifth", data => {

            sound.play('clickuibut', 0.2) // PLAY SOUND

            const { game, player, actions } = this.state
            const { card, index } = data

            let cards = player[index] ? player[index] : []
            cards.unshift({ ...card, isSixth: true })

            let demo_player = player
            demo_player[index] = cards

            let demo_game = game
            demo_game[index] = 'start'

            let demo_action = actions
            demo_action[index] = 0

            this.setState({ player: demo_player, actions: demo_action, shadow: true, game: demo_game })
            this.socket.emit("shadow", "yes")
        })

        /* Get Insurance Status */
        this.socket.on("insuranceStatus", data => {

            this.setState({ insuranceStatus: data })

            if (data[0] === "win" || data[1] === "win") {

                sound.play('winsmall') // PLAY SOUND

                const timer = setTimeout(() => {
                    sound.play('repeat') // PLAY SOUND
                    clearTimeout(timer)
                }, 1000)
            }
            else {
                sound.play('selectchip') // PLAY SOUND
            }
        })

        /* Get a Player card */
        this.socket.on("purchase", data => {
            // const { boxesToPurchase } = this.state
            const { status } = data
            if (this.array_length(status)) {
                this.setState({ isPurchase: status, shadow: true, freezedBoxes: ['bet', 'bet'], boxesToPurchase: this.array_length(status) })
                this.socket.emit("shadow", "yes")
            }
        })

        /* Get a Player card */
        this.socket.on("removedCard", data => {
            this.setState({ removedCard: data })
        })


        /* Check game status */
        this.socket.on("status", data => {

            this.setState({ status: data })

            /* Clear all data when finished */
            if (data === CHOICE) {

                this.loadHistory(1)

                this.setState({

                    /* Game */
                    ante: [0, 0],
                    dealt: false,
                    game: [0, 0],
                    actions: [0, 0],
                    isBet: [0, 0],
                    isOpen: [0, 0],
                    result: [0, 0],

                    activeBoxes: 0,
                    activeButtons: [0, 0],

                    /* Dealer Card */
                    dealer: [],
                    dealerGame: null,

                    /* Player Card */
                    player: [[], []],
                    playerGame: [],

                    /* Exchange */
                    used: [0, 0],
                    exchangeCards: [],

                    /* Purchase */
                    isPurchase: [0, 0],
                    boxesToPurchase: 0,
                    purchaseResult: [0, 0],
                    removedCard: null,

                    betIsTouched: [0, 0],

                    /* Insurance */
                    showInsurance: [0, 0],
                    insurance: [0, 0],
                    insuranceStatus: [0, 0],

                    gameInfo: null,
                    transactions: [],
                    shadow: true,

                    freezedBoxes: [],

                    isPaused: false,
                })
            }
        })

        this.socket.on("result_all", data => {

            const { activeBoxes, isBet } = this.state

            let tempIsBet = isBet

            if (data) {
                const { result } = data
                if (this.array_length(result)) {
                    let winTotal = 0

                    this.setState({ result: result, shadow: true, used: [true, true] })

                    this.socket.emit("shadow", "yes")

                    if (this.array_length(result) === activeBoxes) {
                        this.setState({ total: 0, lastWin: winTotal })

                        result.forEach((item, index) => {
                            if (item && item.result === 'lose') {
                                tempIsBet[index] = 0
                            }
                        })

                        this.setState({ isBet: tempIsBet })

                    }

                    if ((result[0] && result[0].result === "win") || (result[1] && result[1].result === 'win')) {
                        sound.play('win', 0.5) // PLAY SOUND
                    }
                }
            }
        })


        this.socket.on("gameInfo", data => {
            this.setState({ gameInfo: data })
        })

        /* Get refund Ante inform */
        this.socket.on("refundAnte", data => {
            console.log(data)
            const { total, ante } = this.state
            this.notifyToggle("refund", "refund", data)
            this.setState({ total: total - ante[0] })
        })

        this.socket.on("transaction", data => {
            if (data.id) {
                const { transactions, operations } = this.state
                let list = transactions
                let operationsList = operations
                list.push(data)
                operationsList.push(data)
                this.setState({ transactions: list, operations: operationsList })

                setTimeout(() => {
                    let operationsList = this.state.operations
                    operationsList = operationsList.filter(item => item.id !== data.id)
                    this.setState({ operations: operationsList })
                }, 3500)

            }
        })

        this.socket.on("editTransaction", data => {
            const { transactions } = this.state
            let list = transactions
            const index = list.findIndex(e => e.number === data.number)
            if (index > -1) {
                list[index].status = data.status
                this.setState({ transactions: list })
            }
        })


        /* Pause Event */
        this.socket.on("pause", () => {

            this.setState({ isPaused: true })
            this.notifyToggle('pause', 'show')
        })


        /* Continue Event */
        this.socket.on("continue", () => {

            this.setState({ isPaused: false })
            this.notifyToggle('pause', 'close')
        })


        /* Force end */
        this.socket.on("forceEnd", () => {
            this.forceEndClear()
        })


        /* On transaction error */
        this.socket.on("transactionError", () => {

            this.notifyToggle('startError', 'show')

            setTimeout(() => {
                window.location.reload()
            }, 4500)

        })


        /* On Inaction Timing Reset */
        this.socket.on("inactionReset", ({ second, quick }) => {

            if (quick) {
                if (this._timing) {
                    this._timing.reset(second)
                }
            }
            else {
                const resetTimer = setTimeout(() => {

                    if ((second - 2) <= 0) {
                        this.setState({ closed: true })
                        this.socket.disconnect()
                        this.clear()
                    }
                    else {
                        if (this._timing) {
                            this._timing.reset(second - 2)
                        }
                    }

                    clearTimeout(resetTimer)

                }, 2020)
            }
        })

        /* Notification */
        this.socket.on("playerNotification", data => {
            this.notifyToggle('refund', 'refund', data)
        })

        /* On Inaction Timing Disconnect */
        this.socket.on("inactionDisconnect", () => {
            this.setState({ closed: true })
            this.socket.disconnect()
            this.clear()
        })

        /* Message */
        this.socket.on("message", data => {
            if (this._floatingMessages) {
                this._floatingMessages.sendMessage(data)
            }
            if (this._background && this._background.current) {
                this._background.current.addMessage(data)
            }
        })



    }

    /* Cache images */
    cache = async () => {

        const promises = images.data.map(uri => {

            return new Promise(function (resolve, reject) {
                const img = new Image()
                img.src = uri
                img.onload = resolve()
                img.onerror = reject()
            })

        })

        await Promise.all(promises)

        this.setState({ cached: true })
    }


    /* Connect after BUSY */
    connect = () => {
        this.socket.emit("afterConnect")
    }

    /* Set dealer default status */
    setDealerStatus = data => {
        if (this.notify) {
            if (!data) {
                this.notifyToggle('dealer', 'show')
            } else {
                this.notifyToggle('dealer', 'close')
            }
        } else {
            setTimeout(() => {
                this.setDealerStatus(data)
            }, 3000)
        }
    }

    reconnectionClear = () => {

        this.setState({ status: INTERVAL })
        this.setState({
            activeBoxes: 0,
            activeButtons: [0, 0],
            total: 0,
            ante: [0, 0],
            actions: [0, 0],
            dealt: false,
            game: [0, 0],
            isBet: [0, 0],
            isOpen: [0, 0],
            result: [0, 0],
            betIsTouched: [0, 0],
            dealer: [],
            dealerGame: null,
            player: [[], []],
            playerGame: [],
            dealerInfo: null,
            used: [0, 0],
            exchangeCards: [],
            isPurchase: [0, 0],
            boxesToPurchase: 0,
            purchaseResult: [0, 0],
            removedCard: null,
            showInsurance: [0, 0],
            insurance: [0, 0],
            insuranceStatus: [0, 0],
            freezedBoxes: [],
        })
    }


    /* Force end clear */
    forceEndClear = () => {

        this.setState({
            status: CHOICE,
            activeBoxes: 0,
            activeButtons: [0, 0],
            total: 0,
            ante: [0, 0],
            actions: [0, 0],
            dealt: false,
            game: [0, 0],
            isBet: [0, 0],
            isOpen: [0, 0],
            result: [0, 0],
            betIsTouched: [0, 0],
            gameInfo: null,
            transactions: [],
            dealer: [],
            dealerGame: null,
            player: [[], []],
            playerGame: [],
            used: [0, 0],
            exchangeCards: [],
            isPurchase: [0, 0],
            boxesToPurchase: 0,
            purchaseResult: [0, 0],
            removedCard: null,
            showInsurance: [0, 0],
            insurance: [0, 0],
            insuranceStatus: [0, 0],
            freezedBoxes: [],
            isPaused: false

        })
    }

    /* Clear state */
    clear = (status) => {

        this.setState({
            status: status ? status : LOADING,
            activeBoxes: 0,
            activeButtons: [0, 0],
            total: 0,
            ante: [0, 0],
            actions: [0, 0],
            dealt: false,
            game: [0, 0],
            isBet: [0, 0],
            isOpen: [0, 0],
            result: [0, 0],
            betIsTouched: [0, 0],
            gameInfo: null,
            transactions: [],
            dealer: [],
            dealerGame: null,
            player: [[], []],
            playerGame: [],
            dealerInfo: null,
            used: [0, 0],
            exchangeCards: [],
            isPurchase: [0, 0],
            boxesToPurchase: 0,
            purchaseResult: [0, 0],
            removedCard: null,
            showInsurance: [0, 0],
            insurance: [0, 0],
            insuranceStatus: [0, 0],
            freezedBoxes: [],
            isPaused: false,
            isTableEmpty: false
        })
    }


    /* Start Game */
    start = () => {

        const { ante, max, min, currency } = this.state

        let totalBet = 0
        let boxes = 0

        ante.forEach(item => {
            if (item > 0) {
                totalBet += item
                boxes += 1
            }
        })

        const sortedAnte = ante.sort((a, b) => b - a)

        if (parseFloat(sortedAnte[0]) > max) {
            Swal.fire({
                html: `<p class="pincode-error">Максимальная ставка ${utils.convertor(max, currency)}</p>`,
                backdrop: false,
                position: 'top',
                showConfirmButton: false,
                timer: 1500,
                background: '#f44336',
                customClass: 'swal',
                heightAuto: false,
                target: document.getElementById("background")
            })
        }
        else if (parseFloat(sortedAnte[0]) < min) {
            Swal.fire({
                html: `<p class="pincode-error">Минимальная ставка ${utils.convertor(min, currency)}</p>`,
                backdrop: false,
                position: 'top',
                showConfirmButton: false,
                timer: 1500,
                background: '#f44336',
                customClass: 'swal',
                heightAuto: false,
                target: document.getElementById("background")
            })
        }
        else {

            this.socket.emit("start", { ante: sortedAnte, boxes: boxes, totalBet: totalBet })
            this.setState({ total: totalBet, last: sortedAnte, shadow: false, activeBoxes: boxes })
            this.socket.emit("shadow", "no")
        }
    }

    /* Set ante */
    setAnte = (data) => {

        if (data === 'all') {
            this.setState({ ante: [0, 0] })
        }
        else {

            const { value, index } = data
            let new_data = this.state.ante
            if (value <= 0) {
                new_data[index] = 0
            } else {
                new_data[index] = value
            }
            const sortedData = [...new_data].sort((a, b) => parseInt(a) - parseInt(b))

            // let total_ante = 0

            new_data.forEach((item, index) => {
                if (parseInt(item) > 0) {
                    let new_value = sortedData[sortedData.length - 1]
                    if (data.cancel) {
                        new_value = value
                    }
                    // total_ante += new_value
                    new_data[index] = new_value
                }
            })

            // if (max < total_ante) {

            // } else {
            //     this.setState({ ante: new_data, lastWin: 0 })
            // }

            this.setState({ ante: new_data, lastWin: 0 })
        }
    }

    /* Set actions for boxes before bet */
    setAction = (number, action, needToUpdateTotal = true) => {

        /* Fields */
        const { ante, actions, used, activeBoxes, exchangeCards, isBet, showInsurance, freezedBoxes, total, game } = this.state

        let activeActions = actions


        /* Clear box exchange cards if action IS NOT swap */
        if (action !== 'swap' && action !== 0 && !used[number]) {
            let cards = exchangeCards
            cards[number] = []
            this.setState({ exchangeCards: cards })
        }

        /* Case BET */
        if (action === 'bet') {

            let tempIsBet = isBet
            tempIsBet[number] = 'bet'
            this.setState({ isBet: tempIsBet })

            if (needToUpdateTotal) {
                this.setState({ total: total + ante[0] * 2 })
            }

            /* PLAY SOUND */
            sound.play('sound')
        }
        else {

            if (actions[number] === 'bet' && needToUpdateTotal) {
                this.setState({ total: total - ante[0] * 2 })
            }

            let tempIsBet = isBet
            tempIsBet[number] = 'no_bet'
            this.setState({ isBet: tempIsBet })
        }

        /* Case PASS */
        if (action === 'pass') {
            let tempShowInsurance = showInsurance
            tempShowInsurance[number] = 0
            this.setState({ showInsurance: tempShowInsurance })
        }

        activeActions[number] = action
        this.setState({ actions: activeActions })


        /* Action Fields */
        let totalActions = 0
        let totalFinishActions = 0

        /* Determine actions */
        activeActions.forEach(item => {
            if (item) {

                if (item === 'pass' || item === 'bet') {
                    totalFinishActions = totalFinishActions + 1
                }

                totalActions = totalActions + 1
            }
        })
        if (totalActions === activeBoxes && needToUpdateTotal) {

            if ((actions[0] === 'bet' || actions[0] === 'pass' || actions[0] === 0) && (actions[1] === 'bet' || actions[1] === 'pass' || actions[1] === 0)) {

                this.allBet(actions[0], actions[1])

                let freezed = freezedBoxes

                actions.forEach((item, index) => {
                    switch (item) {

                        case 'bet':
                            this.socket.emit("freezed", { index, action: 'bet' })
                            freezed[index] = 'bet'
                            break

                        case 'pass':
                            this.socket.emit("freezed", { index, action: 'pass' })
                            freezed[index] = 'pass'
                            break

                        default:
                            break
                    }
                })

                this.setState({ freezedBoxes: freezed })

                return
            }

            actions.forEach((item, index) => {

                switch (item) {

                    case 'swap':
                        this.swap(index)
                        break

                    case 'fifth':
                        this.fifth(index)
                        break

                    case 'bet':

                        if (totalFinishActions !== totalActions) {
                            this.socket.emit("freezed", { index, action: 'bet' })

                            let tempFreezed = freezedBoxes
                            tempFreezed[index] = 'bet'

                            let tempGame = game
                            tempGame[index] = 'bet'

                            this.setState({ freezedBoxes: tempFreezed, game: tempGame })
                        }

                        break

                    case 'pass':
                        if (totalFinishActions !== totalActions) {
                            this.socket.emit("freezed", { index, action: 'pass' })

                            let tempFreezed = freezedBoxes
                            tempFreezed[index] = 'pass'

                            let tempGame = game
                            tempGame[index] = 'pass'

                            this.setState({ freezedBoxes: tempFreezed, game: tempGame })
                        }

                        break

                    default:
                        break

                }

            })

            /* Update total */
            setTimeout(() => this.updateTotal(actions[0], actions[1]), 500)

        }
    }

    /* Check internet speed */
    checkInternetSpeed = (speed) => {

        if (parseInt(speed) < 5) {
            this.notifyToggle('internet', 'show')
        }
        else {
            this.notifyToggle('internet', 'close')
        }

    }


    /* Update total on fifth or swap */
    updateTotal = (box1, box2) => {

        const { ante, total } = this.state
        let totalSum = total

        if (box1 === 'swap' || box1 === 'fifth') {
            totalSum += ante[0]
        }

        if (box2 === 'swap' || box2 === 'fifth') {
            totalSum += ante[0]
        }

        this.setState({ total: totalSum })
    }


    /* BET OR PASS FOR ALL BOXES */
    allBet = (box1, box2) => {

        const { game } = this.state
        let tempGame = game

        tempGame[0] = box1
        tempGame[1] = box2

        this.setState({ game: tempGame, isBet: [box1, box2], showInsurance: [0, 0] })
        this.socket.emit("allBet", { actions: [box1, box2] })
    }


    /* Bottom Bet action */
    bottomBet = index => {

        const { playerGame, dealt, game, result, isBet } = this.state

        if ((playerGame[index]) && dealt && game[index] === 'start' && !result[index] && !(isBet[index] === "bet")) {
            this.setAction(index, 'bet')
        }
    }


    /* Pass action */
    pass = number => {

        /* Fields */
        const { game } = this.state

        let tempGame = game
        tempGame[number] = "pass"

        this.setState({ game: tempGame, shadow: false })
        this.socket.emit("pass", { status: "ok", box: number })
    }


    /* FIFTH */
    fifth = number => {

        /* Play Sound */
        sound.play('selectchip')

        /* Fields */
        const { game, playerGame, isOpen, used } = this.state

        /* Temporary Data */
        let tempPlayerGame = playerGame
        let tempGame = game
        let tempIsOpen = isOpen
        let tempUsed = used
        tempGame[number] = 'fifth'
        tempPlayerGame[number] = null
        tempIsOpen[number] = 1
        tempUsed[number] = true

        this.socket.emit("fifth", { status: "ok", box: number })
        this.setState({ used: tempUsed, isOpen: tempIsOpen, playerGame: tempPlayerGame, showInsurance: [0, 0], game: tempGame })

        /* Hide Chip */
        setTimeout(() => {
            tempIsOpen[number] = 0
            this.setState({ isOpen: tempIsOpen })
        }, 3000)

    }


    /* SWAP */
    swap = number => {

        const { exchangeCards, game, playerGame, isOpen, used } = this.state

        /* Play Sound */
        sound.play('selectchip')

        let tempPlayerGame = playerGame
        let tempGame = game
        let tempIsOpen = isOpen
        let tempUsed = used

        tempPlayerGame[number] = null
        tempGame[number] = 'exchange'
        tempIsOpen[number] = 1
        tempUsed[number] = true

        this.setState({ used: tempUsed, isOpen: tempIsOpen, playerGame: tempPlayerGame, showInsurance: [0, 0], game: tempGame })
        this.socket.emit("exchange", { cards: exchangeCards, box: number })

        setTimeout(() => {
            tempIsOpen[number] = 0
            this.setState({ isOpen: tempIsOpen })
        }, 3000)

    }

    /* Set exchange cards */
    set_exchange_cards = (data, index) => {

        /* Fields */
        const { cards, card_is_added } = data

        if (!(cards[index].length < 1 && !card_is_added)) {
            this.notifyToggle('swap', 'close')
        }

        this.setAction(index, 0)
        this.setState({ exchangeCards: cards })
    }


    /* Purchase */
    makePurchase = (value, index) => {

        const { isOpen, game, isPurchase, purchaseResult, ante, total } = this.state

        let demo_is_purchase = isPurchase
        let demo_purchase_result = purchaseResult

        demo_purchase_result[index] = value
        demo_is_purchase[index] = 0


        this.setState({ isPurchase: demo_is_purchase, purchaseResult: demo_purchase_result })

        if (value === 'yes') {
            this.setState({ total: total + ante[0] })
        }

        if (this.cache)

            if (this.array_length(demo_is_purchase) === 0) {
                this.socket.emit("purchase", { purchaseResult: demo_purchase_result })

                let demo_is_open = isOpen
                let demo_game = game

                demo_purchase_result.forEach((item, index) => {
                    if (item === 'yes') {
                        demo_is_open[index] = 1
                        demo_game[index] = 'purchase'
                    }
                })

                this.setState({ isOpen: demo_is_open, shadow: false, game: demo_game })
                this.socket.emit("shadow", "no")

                sound.play('selectchip') // PLAY SOUND

                setTimeout(() => {
                    demo_is_open[index] = 0
                    this.setState({ isOpen: demo_is_open })
                }, 3000)
            }
    }


    /* Make Insurance */
    makeInsurance = (value, box) => {

        const { insurance, showInsurance, total, actions, balance } = this.state

        if (balance === null || balance === undefined || parseInt(balance) === 0 || parseInt(balance) <= parseInt(value)) {
            Swal.fire({ title: 'Не хватает средств на балансе', text: 'Чтобы страховаться, пополните свой баланс', timer: 1500, showConfirmButton: false, target: document.getElementById("background") })
            return
        }

        if (actions[box] === 'pass') {
            let demo_actions = actions
            demo_actions[box] = 0
            this.setState({ actions: demo_actions })
        }

        sound.play('selectchip') // PLAY SOUND

        let demoShowInsurance = showInsurance
        demoShowInsurance[box] = 0

        let demoInsurance = insurance
        demoInsurance[box] = value

        this.setState({ insurance: demoInsurance, showInsurance: demoShowInsurance, total: total + parseInt(value) })
        this.socket.emit("insurance", { insurance: value, box: box })
    }

    setHeight = (data) => {
        this.socket.emit("heightBack", data)
    }


    /* Insurance Button */
    _insurance = (index) => {

        const { playerGame, insurance, freezedBoxes } = this.state

        const boxPlayerGame = playerGame[index]

        let status = false

        if (boxPlayerGame && parseInt(boxPlayerGame.level) >= 5) {
            status = true
        }

        if (status && parseInt(insurance[index]) === 0 && !freezedBoxes[index]) {
            return <Insurance onClick={() => this.showBoxInsurance(index, 'show')} />
        }
    }


    /* Show Insurance Box */
    showBoxInsurance = (index, action) => {
        const { showInsurance } = this.state

        let tempShowInsurance = showInsurance

        tempShowInsurance[index] = action === 'show' ? 1 : 0

        this.setState({ showInsurance: tempShowInsurance })
    }

    array_length = (array) => {

        let cnt = 0

        array.forEach(item => {
            if (item) {
                cnt += 1
            }
        })

        return cnt
    }


    /* Bet Balance error */
    toggleBalanceError = () => {
        if (!this._balanceErrorTiming) {
            this.notifyToggle('balanceError', 'show')
        }

        if (this._balanceErrorTiming) {
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }

        this._balanceErrorTiming = setTimeout(() => {
            this.notifyToggle('balanceError', 'close')
            clearTimeout(this._balanceErrorTiming)
            this._balanceErrorTiming = null
        }, 2000)
    }

    /* Toggle Balance Error */
    toggleMaxBetError = () => {

        if (!this._maxBetErrorTiming) {
            this.notifyToggle('maxBetError', 'show')
        }

        if (this._maxBetErrorTiming) {
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }

        this._maxBetErrorTiming = setTimeout(() => {
            this.notifyToggle('maxBetError', 'close')
            clearTimeout(this._maxBetErrorTiming)
            this._maxBetErrorTiming = null
        }, 2000)
    }

    /* Toggle all notify */
    notifyToggle = (type, action, data = null) => {
        const notifyData = { type, action, data }

        if (this._notify) {
            this.notifyAction(notifyData)
        }
        else {
            setTimeout(() => {
                if (this._notify) {
                    this.notifyAction(notifyData)
                }
            }, 2000)
        }
    }

    /* Do notify actions after notify component check */
    notifyAction = (notifyData) => {

        const { type, action, data = null } = notifyData
        if (action === "show") {
            this._notify.show(type)
        }
        else if (action === 'close') {
            this._notify.close(type)
        }
        else if (action === 'refund') {
            if (data.total) {
                const title = data.type === 'refund' ? 'Возврат средств' : data.type === 'refundAnte' ? "Возврат половины анте" : "Пополнение суммы"
                const uid = uuidv4()
                this._notify.refund({ title, total: data.total, uid })
            }
        }

    }

    /* Buttons */
    buttons = (index) => {

        const { result, actions, dealt, used, game, playerGame, exchangeCards, freezedBoxes, insurance, showInsurance, isPurchase, purchaseResult } = this.state

        const position = index === 1 ? 'left' : 'right'
        const buttonStyle = (showInsurance[index] || isPurchase[index] || result[index]) ? 'hidden' : ''
        const swapCards = exchangeCards[index]
        const boxAction = actions[index]
        const boxInsurance = insurance && insurance[index]
        const isBet = freezedBoxes[index] && freezedBoxes[index] === 'bet'
        const isPass = freezedBoxes[index] && freezedBoxes[index] === 'pass'

        const showSwapButton = !used[index] && swapCards && swapCards.length > 0 && !isBet && !isPass
        const showFifthButton = !used[index] && ((swapCards && swapCards.length === 0) || !swapCards) && !isBet && !isPass

        const show = (!this.array_length(result) && (playerGame[index] && !purchaseResult[index])) &&
            dealt && (game[index] === 'start' || game[index] === 'bet' || game[index] === 'pass') &&
            !result[index] && !isPurchase[index] && !purchaseResult[index]

        return (
            <div className={`buttons ${position} ${buttonStyle}`}>

                {show &&
                    <div className="buttons-row">
                        {this._insurance(index)}
                        {showSwapButton &&
                            <Swap selected={boxAction === 'swap'} onClick={() => this.setAction(index, 'swap')} />}
                        {showFifthButton &&
                            <Fifth selected={boxAction === 'fifth'} onClick={() => this.setAction(index, 'fifth')} />}
                        {isPass ? null :
                            <Play selected={boxAction === 'bet'} onClick={() => this.setAction(index, 'bet')} />}
                        {(isBet || boxInsurance) ? null :
                            <Pass selected={boxAction === 'pass'} onClick={() => this.setAction(index, 'pass')} />}
                    </div>
                }
            </div>
        )
    }


    box_insurance = (index) => {

        const { showInsurance, balance, result, ante, dealt, currency, playerGame } = this.state

        const boxAnte = ante[index] ? ante[index] : 0
        const boxPlayerGame = playerGame[index] ? playerGame[index] : 0

        const position = index ? 'left' : 'right'

        const show = (showInsurance[index] && !result[index]) && ante[index] && dealt

        if (show) {
            return (
                <Status
                    status="insurance"
                    currency={currency}
                    balance={balance}
                    position={position}
                    box={index}
                    playerGame={boxPlayerGame}
                    ante={boxAnte}
                    makeInsurance={insurance => this.makeInsurance(insurance, index)}
                    close={() => this.showBoxInsurance(index, "close")}
                />
            )
        }
    }

    box_purchase = (index) => {

        const { ante, result, showInsurance, dealt, isBet, freezedBoxes, currency, actions, game, isPurchase } = this.state

        const position = index ? 'left' : 'right'

        const show = (!result[index] && !showInsurance[index] && ante[index] && dealt && !isBet[index]) || (dealt && freezedBoxes[index])

        if (show) {
            return (
                <Status currency={currency} box={index} result={result} freezedBoxes={freezedBoxes} actions={actions} status={game[index]} position={position} isPurchase={isPurchase[index]} makePurchase={value => this.makePurchase(value, index)} />
            )
        }
    }

    box_result = (index) => {

        const { result, currency } = this.state

        const show = result && result[index]
        if (show) {
            return (
                <Status vip={true} status="result" data={result[index] ? result[index] : null} currency={currency} isPurchase={false} />
            )
        }

    }

    /* DRAW BOX */
    _box = () => {

        const { status, balance, ante, last, isBet, actions, dealer, dealerGame, player, playerGame, dealt, isOpen, result, currency, used, exchangeCards, isPurchase, removedCard, insurance, isTableEmpty, insuranceStatus, freezedBoxes, activeBoxes, activeChip, chips, max } = this.state

        const bigWin = result && ((result[0] && result[0].bigWin) || (result[1] && result[1].bigWin))

        if (isTableEmpty) {
            return <Busy onConnect={() => this.connect()} />
        }

        if (status === INTERVAL) {
            <div />
        }

        const style = activeBoxes === 1 ? 'one-box' : ''

        /* GAME */
        if (status === GAME) {
            return (
                <div className={`play ${style}`}>
                    {
                        BOXES.map((_, index) => {

                            const position = (index === 0) ? 'right' : 'left'
                            const freezed = (freezedBoxes[index] && freezedBoxes[index] === 'pass') ? 'freezed' : ''
                            const boxStyle = ante[index] ? '' : 'hidden'

                            const showFreezeBox = freezedBoxes[index] && !isPurchase[index]

                            const showPlayerCards = (player[index] && Array.isArray(player[index]))

                            const boxPlayerGame = playerGame[index] ? playerGame[index] : null

                            return (
                                <div className={`card-box ${position}`} key={`${index}`}>

                                    {index === 0 ? <DealerCards cards={dealer} game={dealerGame} dealt={dealt} removedCard={removedCard} /> : <div className="dealer-cards-box left" />}

                                    <div className={`card-box-bottom ${position} ${boxStyle}`}>

                                        <p className="boxNumber">Бокс {index + 1}</p>

                                        <VipMobileRate
                                            actions={actions}
                                            currency={currency}
                                            position={position}
                                            box={index}
                                            ante={ante}
                                            result={result}
                                            activeBoxes={activeBoxes}
                                            freezedBoxes={freezedBoxes}
                                            isBottom={true}
                                            isBet={isBet}
                                            isOpen={isOpen}
                                            insurance={insurance}
                                            insuranceStatus={insuranceStatus}
                                            add={() => { }}
                                            bet={box => this.bottomBet(box)}
                                            chips={chips}
                                        />

                                        <div className={`player-game ${freezed}`}>

                                            {showFreezeBox ? <div className="freeze_box" /> : null}

                                            {showPlayerCards ?
                                                <PlayerCards
                                                    position={position}
                                                    box={index}
                                                    cards={player[index]}
                                                    game={boxPlayerGame}
                                                    dealt={dealt}
                                                    exchangeCards={exchangeCards}
                                                    setExchangeCards={data => this.set_exchange_cards(data, index)}
                                                    used={used[index]}
                                                />
                                                : null}

                                            {this.buttons(index)}

                                            {this.box_insurance(index)}
                                            {this.box_purchase(index)}
                                            {this.box_result(index)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <VipRates
                        actions={actions}
                        currency={currency}
                        gameScreen={true}
                        ante={ante}
                        playerGame={playerGame}
                        result={result}
                        dealt={dealt}
                        boxes={BOXES}
                        isBottom={true}
                        isBet={isBet}
                        isOpen={isOpen}
                        insurance={insurance}
                        insuranceStatus={insuranceStatus}
                        add={() => { }}
                        bet={box => this.bottomBet(box)}
                        chips={chips}
                    />

                    {
                        bigWin ?
                            <div>
                                <div class="firework"></div>
                                <div class="firework"></div>
                                <div class="firework"></div>
                                <div class="firework"></div>
                                <div class="firework"></div>
                                <div class="firework"></div>
                            </div>
                            : null
                    }
                </div>
            )
        }

        /* CHOICE */
        if (status === CHOICE) {
            return (
                <Bet
                    activeBoxes={activeBoxes}
                    ante={ante}
                    last={last}
                    max={max}
                    setAnte={data => this.setAnte(data)}
                    balance={balance}
                    boxes={BOXES}
                    result={result}
                    start={() => this.start()}
                    currency={currency}
                    toggleBalanceError={() => this.toggleBalanceError()}
                    toggleMaxBetError={() => this.toggleMaxBetError()}
                    activeChip={activeChip}
                    setActiveChip={value => this.setState({ activeChip: value })}
                    chips={chips}
                />
            )
        }

        return <div />
    }


    render = () => {

        const { status, cached, dealerInfo, currency, total, balance, lastWin, transactions, gameInfo, operations, histories, historyPage, historyHasMore, closed, newDevice, max, min, maxPay, isDisconnected, isPaused, identifier, hls, backColor, gameImg } = this.state

        if (status === ERROR || newDevice || closed || isDisconnected) {
            return <Error newDevice={newDevice} closed={closed} isDisconnected={isDisconnected} />
        }

        if (status === LOADING || !cached) {
            return <Preloader url={gameImg} />
        }

        const gamePayout = <GamePayout maxPay={maxPay} currency={currency} />

        return (
            <Background gameType="vip" url={hls} currency={currency} maxPay={maxPay} status={status} dealer={dealerInfo} sendMessage={message => this.sendMessage(message)} info={gameInfo} transactions={transactions} histories={histories} gamePayout={gamePayout} loadMore={() => this.loadHistory(historyPage + 1)} historyHasMore={historyHasMore} identifier={identifier} rules={list} videoBackImg={gameImg} textileColor={backColor}>
                <HookWrapper />
                <Timing ref={ref => this._timing = ref} status={status} isPaused={isPaused} />
                <GameControl title={`Vip European Poker`} maxPay={maxPay} max={max} min={min} currency={currency} info={gameInfo} data={dealerInfo} gamePayout={gamePayout} onQuitGame={() => this.quitGame()} />
                <GameInfo currency={currency} info={gameInfo} operations={operations} />
                <Balance balance={balance} total={total} lastWin={lastWin} currency={currency} />
                <FloatingMessages ref={ref => this._floatingMessages = ref} />

                {this._box()}

                <div className="bottom-shadow" />
                <Notification nextGame={false} ref={ref => this._notify = ref} currency={currency} max={max} />
                <Internet setInternetSpeed={data => this.checkInternetSpeed(data)} url={imgUrl} />
            </Background>
        )
    }

}

export default App

function HookWrapper() {
    useSendInitIframeMessage()
    return null
}