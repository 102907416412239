import React, { Component } from "react"

/* Constants */
import { env, utils } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"


class GamePayout extends Component {

    render = () => {

        const { maxPay, currency } = this.props

        return (
            <>
                <div className="head">
                    <p>Максимальная выплата</p>
                    <p className="price">{utils.convertor(maxPay, currency, " ")}</p>
                </div>
                <div className="row">
                    <p>Комбинации</p>
                    <p>Выплата</p>
                </div>
                <div className="combinations">
                    <div className="item">
                        <p>Туз-Король на 5-ти картах</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/aceKing.svg`} alt="aceking" />
                        </div>
                        <p className="result">1:1</p>
                    </div>
                    <div className="item">
                        <p>Туз-Король на 4-ти картах</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/aceKing.svg`} alt="aceking" />
                        </div>
                        <p className="result">3:1</p>
                    </div>
                    <div className="item">
                        <p>Пара</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/pair.svg`} alt="pair" />
                        </div>
                        <p className="result">1:1</p>
                    </div>
                    <div className="item">
                        <p>Две пары</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/twopairs.svg`} alt="twopairs" />
                        </div>
                        <p className="result">3:1</p>
                    </div>
                    <div className="item">
                        <p>Тройка</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/threeofakind.svg`} alt="twopairs" />
                        </div>
                        <p className="result">4:1</p>
                    </div>
                    <div className="item">
                        <p>Стрит</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/street.svg`} alt="twopairs" />
                        </div>
                        <p className="result">5:1</p>
                    </div>
                    <div className="item">
                        <p>Флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/flash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">6:1</p>
                    </div>
                    <div className="item">
                        <p>Фулл хаус</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/fullhouse.svg`} alt="twopairs" />
                        </div>
                        <p className="result">7:1</p>
                    </div>
                    <div className="item">
                        <p>Каре на 5-ти картах</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/fourofakind.svg`} alt="twopairs" />
                        </div>
                        <p className="result">20:1</p>
                    </div>
                    <div className="item">
                        <p>Каре на 4-ти картах</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/fourofakind.svg`} alt="twopairs" />
                        </div>
                        <p className="result">40:1</p>
                    </div>
                    <div className="item">
                        <p>Стрит флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/streetFlash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">60:1</p>
                    </div>
                    <div className="item">
                        <p>Рояль флеш</p>
                        <div>
                            <Picture src={`${env.mediapoint}/images/combinations/royalFlash.svg`} alt="twopairs" />
                        </div>
                        <p className="result">120:1</p>
                    </div>
                </div>
            </>
        )
    }
}

export default GamePayout