import React, { Component } from "react"

/* Constants */
import { env } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"

const tempCards = [0, 1, 2, 3, 4]

/* Widget Dealer Cards */
class DealerCards extends Component {


    /* Card status */
    status = card => {

        const { game } = this.props

        let status = false

        if (game && game.data) {
            const index = game.data.findIndex(e => parseInt(e.id) === parseInt(card.id))
            if (index > -1) {
                if (game.data[index].status) {
                    status = true
                }
            }
        }

        return status
    }

    /* Draw card image */
    _image = (card, index) => {

        const { game, cards } = this.props

        const filtered_cards = this.get_unique_values(cards, 'id')

        const status = this.status(card)
        const style = status ? 'animated-card-image' : ''

        if ((index === 0 && filtered_cards.length > 4) || game !== null) {
            return <Picture className={style} src={`${env.mediapoint}/cards/${card.image}`} alt="Dealer Card" />
        }

        return <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Dealer Card" />
    }


    /* Draw flip animation box */
    _flip = (card, index) => {

        const { game, cards } = this.props
        const filtered_cards = this.get_unique_values(cards, 'id')

        const isActive = ((index === 0 && filtered_cards.length > 4) || game !== null) ? 'active' : ''

        return (
            <div className={`dealer-flip-card ${isActive}`}>
                <div className="dealer-flip-card-inner">
                    <div className="dealer-flip-card-front">
                        <Picture src={`${env.mediapoint}/cards/closed.webp`} alt="Dealer Card" />
                    </div>
                    <div className="dealer-flip-card-back">
                        {this._image(card, index)}
                    </div>
                </div>
            </div>
        )
    }

    /* Draw Status */
    _status = card => {

        const status = this.status(card)

        return (
            <div className={`dealer-card-status ${status ? 'dealer-card-status-animation' : ''}`}>

                {(card.isExchange && card.isExchange === true) &&
                    <div className="dealer-mini-status">
                        <Picture src={`${env.mediapoint}/images/exchange.png`} alt="Exchange" />
                    </div>
                }

                {status && <div className="dealer-card-status-corner" />}
            </div>
        )

    }

    get_unique_values = (arr, prop) => {
        return arr.filter((obj, index, self) =>
            index === self.findIndex((o) =>
                o[prop] === obj[prop]
            )
        )
    }

    /* Draw card */
    _card = () => {

        const { cards, removedCard } = this.props

        const filtered_cards = this.get_unique_values(cards, 'id')

        return (
            <div className="dealer-cards">
                {filtered_cards.map((card, index) => {

                    const status = removedCard !== null && parseInt(card.id) === parseInt(removedCard.id)

                    return (
                        <div className={`dealer-card ${status ? 'removed' : ''}`} key={`${card.uid}`}>
                            {this._flip(card, index)}
                            {status &&
                                <div className="removed-card">
                                    <Picture src={`${env.mediapoint}/images/x.png`} alt="X" />
                                </div>
                            }
                            {this._status(card)}
                        </div>
                    )
                })}
                <div className="dealer-cards-places">
                {
                    tempCards.map(item => 
                        <div key={`place-${item}`} className="card-place"/>    
                    )
                }
                </div>
            </div>
        )
    }


    /* Draw dealer game result */
    _result = (style = '') => {

        const { game } = this.props

        if (game !== null) {
            if (parseInt(game.level) === 0) {
                return <div className={`dealer-result dealer-result-red ${style}`}>Нет игры</div>
            }
            if (parseInt(game.level) > 0) {
                return <div className={`dealer-result ${style}`}>{game.name}</div>
            }
        }
    }


    render = () => {

        return (
            <div className="dealer-cards-box">
                <h2 style={{ opacity: 1 }}>Рука дилера {this._result('mobile')}</h2>
                {this._card()}
                {this._result()}
            </div>
        )
    }

}

export default DealerCards